export default [
  {
    header: 'ระบบธุรกรรม',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'ฝากเงิน',
    route: 'deposit',
    icon: 'DollarSignIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'เครดิต (รอกฝาก)',
    route: 'creditwait',
    icon: 'PlusIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'เครดิต',
    route: 'credit',
    icon: 'PlusIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'ถอนเงิน',
    route: 'withdraw',
    icon: 'MinusIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'โยกเงินออก',
    route: 'TransferOut',
    icon: 'CreditCardIcon',
    resource: 'admin',
    action: 'read',
  },
]
