export default [
  {
    header: 'ระบบสมาชิก',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'สมาชิก',
    route: 'user',
    icon: 'UsersIcon',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'เอเย่นต์',
    route: 'agent',
    icon: 'UsersIcon',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'แนะนำเพื่อน',
    route: 'ref',
    icon: 'UsersIcon',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'โบนัสแนะนำเพื่อน',
    route: 'bonus_ref',
    icon: 'MailIcon',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'โบนัสคืนยอดเสีย',
    route: 'bonus_return_balance',
    icon: 'MailIcon',
    resource: 'user',
    action: 'read',
  },
]
