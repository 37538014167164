export default [
  {
    header: 'ตั้งค่าระบบ',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'โปรโมชั่น',
    route: 'promotion',
    icon: 'InboxIcon',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'ประกาศ',
    route: 'news',
    icon: 'PaperclipIcon',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'ตั้งค่าธนาคาร',
    route: 'bank',
    icon: 'CopyIcon',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'ตั้งค่าเว็บ',
    route: 'web_setting',
    icon: 'SettingsIcon',
    resource: 'admin',
    action: 'read',
  },
  {
    title: 'พนักงาน',
    route: 'auth_setting',
    icon: 'UsersIcon',
    resource: 'admin',
    action: 'read',
  },
]
