<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unread"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          รายการแจ้งเตือน
        </h4>
        <b-badge
          v-if="unread > 0"
          pill
          variant="light-primary"
        >
          {{ unread }} รายการใหม่
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >

      <!-- System Notification Toggler -->
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          เปิด/ปิด เสียงแจ้งเตือน
        </h6>
        <b-form-checkbox
          v-model="toggle_play_sound"
          :checked="true"
          @change="toggle_play"
          switch
        />
      </div>

      <!-- Account Notification -->
      <b-link
        v-for="(notification) in compNotificationList"
        :key="notification.id"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>

      
    </vue-perfect-scrollbar>
  


    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="unread = 0"
    >ทำเป็นอ่านแล้วทั้งหมด</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      unread: 0,
      toggle_play_sound: true,
      chk_play_sound: false,
      notifications: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    compNotificationList() {
      return this.notifications;
    },
  },
  mounted() {
    document.getElementById("alert-sound").pause()
  },
  created() {
    setInterval(()=>{
      this.fetch_notifications()
    }, 15000)
  },
  methods: {
    toggle_play() {
      if (!this.toggle_play_sound) {
        document.getElementById("alert-sound").pause()
      }
    },
    sound_play() {
      setTimeout(function(){
        document.getElementById("alert-sound").pause()
        document.getElementById("alert-sound").currentTime = 0
        document.getElementById("alert-sound").volumn = 100
        document.getElementById("alert-sound").play()
      }, 1500)
    },
    title(text) {
      return `แจ้งถอนเงิน (${text})`
    },
    status(value) {
      return value.status == 0 ? 'รออนุมัติ' : value.status == 1 ? 'อนุมัติ (ถอนออโต้)' : value.status == 2 ? 'ไม่อนุมัติ' : value.status == 3 ? 'อนุมัติ (ถอนมือ)' : 'ดำเนินการถอนออโต้'
    },
    add_noty(value) {
      let status = this.status(value)
      this.notifications.unshift({
        title: this.title(status),
        avatar: require(`@/assets/images/banks/${value.bank.replace('0','')}.png`),
        subtitle: 'แจ้งถอนเงิน ยูสเซอร์ : '+value.username+' จำนวน => '+value.amount+' บาท',
        type: 'light-success',
        id: value.id,
      })
      if (this.notifications.length > 25) {
        this.notifications.pop();
        if (this.unread > 25) this.unread = 25;
      }
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: this.title(status),
          icon: 'BellIcon',
          variant: 'success',
          text: 'แจ้งถอนเงิน ยูสเซอร์ : '+value.username+' จำนวน => '+value.amount+' บาท',
        },
      })
    },
    update_noty(value) {
      this.notifications.forEach((element, index) => {
        if(element.id == value.id && element.status != value.status) {
          this.notifications[index].title = this.title(this.status(value));
        }
      });
    },
    reverseArr(input) {
      var ret = new Array;
      for(var i = input.length-1; i >= 0; i--) {
        ret.push(input[i]);
      }
      return ret;
    },
    load(response) {
      if (response == null || response.data == null) return;
      var wd_recordsTotal = window.localStorage.getItem("wd_recordsTotal");
      if(wd_recordsTotal!=null && wd_recordsTotal != "" && typeof(wd_recordsTotal) != "undefined" && parseInt(response.recordsTotal) > parseInt(wd_recordsTotal)){
        window.localStorage.setItem("wd_recordsTotal",parseInt(response.recordsTotal));
        this.chk_play_sound = false;
        var data = this.reverseArr(response.data);
        for(var i=0;i<data.length;i++){
          var value = data[i];
          var wd_maxId = window.localStorage.getItem("wd_maxId");
          wd_maxId = wd_maxId!=null && wd_maxId != "" && typeof(wd_maxId) != "undefined" ? parseInt(wd_maxId) : 0;
          if(parseInt(value.id) > parseInt(wd_maxId) && value.status == '0'){
            this.chk_play_sound = true;
            window.localStorage.setItem("wd_maxId",parseInt(value.id));
            this.unread++;
            this.add_noty(value);
            if(this.chk_play_sound && this.toggle_play_sound){
              this.sound_play();
            }
            break;
          } else {
            this.update_noty(value)
          }
        }
        if(!this.chk_play_sound){
        }
      }else{
        window.localStorage.setItem("wd_recordsTotal",parseInt(response.recordsTotal));
        this.chk_play_sound = false;
        var data = this.reverseArr(response.data);
        for(var i=0;i<data.length;i++) {
          var value = data[i];
          var wd_maxId = window.localStorage.getItem("wd_maxId");
          wd_maxId = wd_maxId!=null && wd_maxId != "" && typeof(wd_maxId) != "undefined" ? parseInt(wd_maxId) : 0;
          if(parseInt(value.id) > parseInt(wd_maxId) && value.status == '0'){
            this.chk_play_sound = true;
            window.localStorage.setItem("wd_maxId",parseInt(value.id));
            this.unread++;
            this.add_noty(value);
            if(this.chk_play_sound && this.toggle_play_sound){
              this.sound_play();
            }
            break;
          } else {
            this.update_noty(value)
          }
        }
        if(!this.chk_play_sound){
        }
      }
    },
    fetch_notifications() {
      this.$http.get('withdraw/withdraw_list_no_paginate_page').then(res => this.load(res.data));
    },
  },
}
</script>

<style>

</style>
