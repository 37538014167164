export default [
  {
    header: 'รายงานสรุป',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'กำไร/ขาดทุน',
    route: 'report',
    icon: 'ActivityIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'ภาพรวมสมาชิก',
    route: 'report_member',
    icon: 'TrendingUpIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'สรุปเอเย่นต์',
    route: 'report_agent',
    icon: 'ArchiveIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'สรุปโปรโมชั่น',
    route: 'report_promotion',
    icon: 'ArchiveIcon',
    resource: 'credit',
    action: 'read',
  },
]
