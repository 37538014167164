export default [
  {
    header: 'ระบบ LOGS',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'SMS',
    route: 'log_Sms',
    icon: 'MessageCircleIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'Line notify',
    route: 'log_LineNotify',
    icon: 'MessageSquareIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'วงล้อนำโชค',
    route: 'log_Wheel',
    icon: 'MessageSquareIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'สะสมแต้ม',
    route: 'log_Reward',
    icon: 'MessageSquareIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'ฝาก-ถอน',
    route: 'log_DepositWithdraw',
    icon: 'CalendarIcon',
    resource: 'credit',
    action: 'read',
  },
  {
    title: 'เปิดหน้าเว็บ',
    route: 'log_Page',
    icon: 'CalendarIcon',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'โยกเงิน',
    route: 'log_TrasferOut',
    icon: 'CalendarIcon',
    resource: 'admin',
    action: 'read',
  },
]
